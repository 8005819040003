<template>
  <div class="game_stats_box">
    <div class="game_stats">
      <div class="top mortina">
        <icon_back @click="back" class="onlymobile" />
        <div @click="onIndex = 0">
          <span :class="{ active: onIndex == 0 }">{{langTxt[lang].gameStats.tab[0]}}</span>
        </div>
        <div @click="onIndex = 1">
          <span :class="{ active: onIndex == 1 }">{{langTxt[lang].gameStats.tab[1]}}</span>
        </div>
        <div @click="onIndex = 2">
          <span :class="{ active: onIndex == 2 }">{{langTxt[lang].gameStats.tab[2]}}</span>
        </div>
      </div>
      <div class="data">
        <div class="gold">
          <div class="net">
            <div class="ico">
              <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.2971 10.0626C13.1654 10.0626 16.1465 9.41856 17.9362 8.19218V10.4273H19.5946V5.53134C19.5946 2.58815 14.8043 1 10.2971 1C5.78999 1 1 2.58815 1 5.53151V18.888C1 21.8312 5.7902 23.4192 10.2973 23.4192V21.7261C5.56432 21.7261 2.65834 20.073 2.65834 18.888V14.8708C4.44827 16.097 7.42923 16.7411 10.2973 16.7411V15.0478C5.56432 15.0478 2.65834 13.3948 2.65834 12.2096V8.19235C4.44797 9.41856 7.42881 10.0626 10.2971 10.0626ZM10.2971 2.69312C15.0303 2.69312 17.9362 4.34633 17.9362 5.53134C17.9362 6.71643 15.0303 8.36948 10.2971 8.36948C5.56427 8.36948 2.65817 6.71643 2.65817 5.53134C2.65821 4.34629 5.56431 2.69312 10.2971 2.69312ZM17.7292 10.988C13.6123 10.988 11.4582 12.6053 11.4582 14.2026V22.7856C11.4582 24.3829 13.6123 26 17.7292 26C21.8151 26 23.9665 24.4069 23.9984 22.8212L24 14.2024C23.9999 12.6051 21.8461 10.988 17.7292 10.988ZM17.7292 12.6813C20.7724 12.6813 22.3417 13.7475 22.3417 14.2026C22.3417 14.6577 20.7724 15.7241 17.7292 15.7241C14.6857 15.7241 13.1164 14.6578 13.1164 14.2026C13.1164 13.7475 14.686 12.6813 17.7292 12.6813ZM17.7292 24.3064C14.6857 24.3064 13.1164 23.2401 13.1164 22.7851V20.7363C14.1687 21.3197 15.7129 21.7081 17.7292 21.7081C19.7453 21.7081 21.2895 21.3195 22.3417 20.7363V22.7851C22.3417 23.2401 20.7724 24.3064 17.7292 24.3064ZM17.7292 20.015C14.6857 20.015 13.1164 18.9488 13.1164 18.4939V16.4449C14.1687 17.0283 15.7129 17.4169 17.7292 17.4169C19.7453 17.4169 21.2895 17.0286 22.3417 16.4452V18.4942C22.3417 18.9488 20.7724 20.015 17.7292 20.015Z"
                  fill="white" stroke="white" stroke-width="0.5" />
              </svg>
            </div>
            <div class="info">
              <div class="title">{{langTxt[lang].gameStats.gold[0]}}</div>
              <div class="value">
                {{ staticsData ? staticsData[typeStr].earning : "-" }}
              </div>
            </div>
          </div>
          <div class="deposit">
            <div class="ico">
              <svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.2857 13.4167C20.3508 13.4167 21.2143 12.5585 21.2143 11.5C21.2143 10.4415 20.3508 9.58333 19.2857 9.58333C18.2206 9.58333 17.3571 10.4415 17.3571 11.5C17.3571 12.5585 18.2206 13.4167 19.2857 13.4167Z"
                  fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M27 7.66667C27 6.60812 26.1365 5.75 25.0714 5.75V1.91667C25.0714 0.858121 24.208 0 23.1429 0H1.92857C0.863451 0 0 0.85812 0 1.91667V5.93254H2.89286V2.875H22.1786V5.75H13.5C12.4349 5.75 11.5714 6.60812 11.5714 7.66667V15.3333C11.5714 16.3919 12.4349 17.25 13.5 17.25H22.1786V20.125H2.89286V15.4246H0V21.0833C0 22.1419 0.863451 23 1.92857 23H23.1429C24.208 23 25.0714 22.1419 25.0714 21.0833V17.25C26.1365 17.25 27 16.3919 27 15.3333V7.66667ZM14.4643 14.375V8.625H24.1071V14.375H14.4643Z"
                  fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.12519 6.28006C5.59143 5.8167 6.34735 5.8167 6.81359 6.28006L9.55102 9.0006C10.4835 9.92731 10.4835 11.4298 9.55102 12.3565L6.81359 15.0771C6.34735 15.5404 5.59143 15.5404 5.12519 15.0771C4.65895 14.6137 4.65895 13.8625 5.12519 13.3991L7.86262 10.6786L5.12519 7.95804C4.65895 7.49468 4.65895 6.74342 5.12519 6.28006Z"
                  fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9.49206H8.35714V11.8651H0V9.49206Z" fill="white" />
              </svg>
            </div>
            <div class="info">
              <div class="title">{{langTxt[lang].gameStats.gold[1]}}
                {{onIndex == 0?langTxt[lang].gameStats.tab[0]:onIndex ==1?langTxt[lang].gameStats.tab[1]:langTxt[lang].gameStats.tab[2] }}
                ({{langTxt[lang].gameStats.gold[3]}})
              </div>
              <div class="value">
                {{ staticsData ? staticsData[typeStr].exchange : "-" }}
              </div>
            </div>
          </div>
          <div class="withdraw">
            <div class="ico">
              <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M22.0867 2.31967C22.0867 0.697831 20.4144 -0.422415 18.8521 0.152775L3.36273 5.85526H1.92857C0.863451 5.85526 0 6.69332 0 7.72712V24.1281C0 25.1619 0.863451 26 1.92857 26H23.1429C24.208 26 25.0714 25.1619 25.0714 24.1281V21.5432C26.1365 21.5432 27 20.7051 27 19.6713V12.1839C27 11.1501 26.1365 10.3121 25.0714 10.3121V7.72712C25.0714 6.69332 24.208 5.85526 23.1429 5.85526H22.0867V2.31967ZM19.6989 5.85526V2.31967L10.0954 5.85526H19.6989ZM2.89286 23.1922V8.66305H22.1786V10.3121H13.5C12.4349 10.3121 11.5714 11.1501 11.5714 12.1839V19.6713C11.5714 20.7051 12.4349 21.5432 13.5 21.5432H22.1786V23.1922H2.89286ZM14.4643 18.7354V13.1198H24.1071V18.7354H14.4643ZM19.2857 17.7995C20.3508 17.7995 21.2143 16.9614 21.2143 15.9276C21.2143 14.8938 20.3508 14.0558 19.2857 14.0558C18.2206 14.0558 17.3571 14.8938 17.3571 15.9276C17.3571 16.9614 18.2206 17.7995 19.2857 17.7995Z"
                  fill="white" />
              </svg>
            </div>
            <div class="info">
              <div class="title">{{langTxt[lang].gameStats.gold[2]}}
                {{onIndex == 0?langTxt[lang].gameStats.tab[0]:onIndex ==1?langTxt[lang].gameStats.tab[1]:langTxt[lang].gameStats.tab[2] }}
                ({{langTxt[lang].gameStats.gold[3]}})</div>
              <div class="value">
                {{ staticsData ? staticsData[typeStr].withdraw : "-" }}
              </div>
            </div>
          </div>
        </div>
        <dl>
          <dd>
            <span class="label">{{langTxt[lang].gameStats.data[0]}}</span>
            <span class="value"><span class="money">{{
                staticsData ? staticsData[typeStr].task_award : "-"
              }}</span></span>
          </dd>
          <dd>
            <span class="label">{{langTxt[lang].gameStats.data[1]}}</span>
            <span class="value"><span class="money">{{
                staticsData ? staticsData[typeStr].archievement_reward : "-"
              }}</span></span>
          </dd>
          <dd>
            <span class="label">{{langTxt[lang].gameStats.data[2]}}</span>
            <span class="value"><span>{{
                staticsData ? staticsData[typeStr].gold_medal_count : "-"
              }}</span></span>
          </dd>
          <dd>
            <span class="label">{{langTxt[lang].gameStats.data[3]}}</span>
            <span class="value"><span>{{
                staticsData ? staticsData[typeStr].silver_medal_count : "-"
              }}</span></span>
          </dd>
          <dd>
            <span class="label">{{langTxt[lang].gameStats.data[4]}}</span>
            <span class="value"><span>{{
                staticsData ? staticsData[typeStr].game_iten_card_count : "-"
              }}</span></span>
          </dd>
          <dd>
            <span class="label">{{langTxt[lang].gameStats.data[5]}}</span>
            <span class="value"><span class="money">{{
                staticsData ? staticsData[typeStr].game_item_card_reward : "-"
              }}</span></span>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import {
    computed,
    defineComponent,
    onMounted,
    ref,
    watch
  } from "vue";
  import common from "../utils/common";
  import {
    useStore
  } from "vuex";
  import icon_back from "../components/ui/icon/icon_back.vue";
import { useRouter } from "vue-router";

  export default defineComponent({
    name: "game_stats",
    components: {icon_back},
    setup() {
      let store = useStore();
      let router = useRouter();
      const back = ()=>{
        router.back();
      };

      let staticsData = ref(null);

      //根据onIndex读不同的字段值
      let typeStr = ref("today");
      let onIndex = ref(0);
      const getSysStatistInfo = () => {
        if (!store.state.uid && !common.userApi.token) {
          return;
        }
        common.showLoading("getSysStatistInfo");
        const sendUrl = common.userApi.requestUrl + "/misc_api/sys_statist_info";
        const sendHeaders = common.buildHeaders("form");
        axios
          .get(sendUrl, {
            headers: sendHeaders
          })
          .then((r) => {
            console.log('--lb',r);
            if (r.data.code == 0) {
              staticsData.value = {
                today: r.data.today,
                total: r.data.total,
                yestoday: r.data.yestoday,
              };
            } else {
              toastr["error"](r.data.msg);
            }
          })
          .catch((e) => {
            toastr["error"](e);
          })
          .finally(() => {
            common.hideLoading("getSysStatistInfo");
          });
      };
      watch(onIndex, (newValue, oldValue) => {
        if (onIndex.value == 0) {
          typeStr.value = "today";
        } else if (onIndex.value == 1) {
          typeStr.value = "yestoday";
        } else {
          typeStr.value = "total";
        }
        // console.log('--lb',onIndex.value, typeStr.value);
      });

      //登录后才去请求数据
      let myUid = computed(() => {
        return store.state.uid;
      });
      if (!myUid.value && !common.userApi.token) {
        common.showLogin();
      } else {
        getSysStatistInfo();
      }
      watch(myUid, (newValue, oldValue) => {
        if (!myUid.value && !common.userApi.token) {
          common.showLogin();
        } else if (myUid.value) {
          getSysStatistInfo();
        }
      });

      onMounted(() => {
        getSysStatistInfo();
      });
      var lang = computed(() => {
        return store.state.lang;
      });
      var langTxt = store.state.text;

      return {
        langTxt,
        lang,
        onIndex,
        staticsData,
        typeStr,back
      };
    },
  });
</script>
<style scoped lang="scss">
  .game_stats {
    margin: 0.7rem auto 0 auto;
    width: 12rem;
    height: 6.45rem;
    background: #1b203f;
    border: 1px solid #38366c;
    border-radius: 0.2rem;
    box-sizing: border-box;
    color: #fff;

    .top {
      height: 0.7rem;
      line-height: 0.7rem;
      border-bottom: 1px solid #38366c;
      color: #fff;
      font-size: 0.2rem;
      display: flex;
      text-align: center;

      div {
        width: 2.05rem;
        height: 100%;
        cursor: pointer;
      }

      span {
        display: inline-block;
        height: 100%;
        position: relative;
        opacity: 0.5;

        &.active {
          opacity: 1;

          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 0.03rem;
            background: var(--linearGradientRedHover);
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
    }

    .data {
      padding: 0 0.4rem;

      .gold {
        height: 1.6rem;
        border-bottom: 1px solid #38366c;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &>div {
          display: flex;
          align-items: center;
        }

        .title {
          color: rgba(255, 255, 255, 0.8);
          font-size: 0.18rem;
        }

        .ico {
          width: 0.7rem;
          height: 0.7rem;
          background: #2a2958;
          margin-right: 0.2rem;
          border: 1px solid #38366c;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .value {
          font-size: 0.3rem;
          line-height: 0.42rem;
          background: var(--linearGradientRed);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          color: transparent;
        }
      }

      dl {
        display: flex;
        margin: 0;
        margin-top: 0.3rem;
        flex-wrap: wrap;
        justify-content: space-around;

        dd {
          font-size: 0.2rem;
          margin: 0;
          width: 5rem;
          height: 0.7rem;
          padding: 0 0.2rem;
          margin: 0.2rem 0;
          background: #2a2958;
          border-radius: 20px;
          flex-shrink: 0;
          flex-grow: 0;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .label {
            text-transform: capitalize;
            color: rgba(255, 255, 255, 0.8);
          }

          .money {
            padding-left: 0.2rem;
            background: url("https://cdn.blockwar.io/web_assets/img/ico_exchange_gold.png") no-repeat left center;
            background-size: 0.15rem auto;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1059px){
    .game_stats_box{
      .game_stats{
        width: auto;
        height: auto;
        margin: 0;
        border: 0;
        border-radius: 0;
        padding-bottom: 1.5rem;
        .top{
          height: .96rem;
          padding: 0 0.32rem;
          display: flex;
          align-items: center;
          .icon_back{
            width: .48rem;
            height: .48rem;
          }
          span{
            font-size: .36rem;
            opacity: 1;
            line-height: .96rem;
            &.active{
              color: #EF4F55;
            }
          }
        }
        .data{
          padding: 0;
          .gold{
            flex-direction: column;
            height: auto;
            >div{
              padding-left: 1.4rem;
              width: 100%;
              height: 1.42rem;
              box-sizing: border-box;
              .title{
                font-size: .32rem;
              }
              .value{
                font-size: .44rem;
              }
              .ico{
                width: .96rem;
                height: .96rem;
                svg{
                  width: .4rem;
                  height: auto;
                }
              }
            }
          }
          dl{
            width: 6.86rem;
            margin: 0 .32rem;
            padding-top: .1rem;
            dd{
              width: 100%;
              margin: .1rem 0;
              height: 1rem;
              font-size: .28rem;
              border-radius: 0;
              .money{
                width: auto;
                height: .3rem;
                background-size: .3rem .3rem;
                padding-left: .4rem;
              }
            }
          }
        }
      }
    }
  }
</style>